<div>
  <mat-card>
    <div class="intro-container">
      <div class="bodyText mat-body">
        <h2>This website represents an attempt to collect and archive old content taught from years
          2011
          to 2016.</h2>
        <h2>Life Church is a faith community that makes space for every race, ethnicity, age, sexual orientation and
          gender
          identity. We welcome belief and doubt, devotion and exploration, while hoping to connect with God, one
          another
          and our world.
        </h2>
        <h2>This website serves as a way to interact with older content from the early days of Life Church, you can
          download, request an english text transcript or read the sermon</h2>

      </div>
    </div>
  </mat-card>

</div>
