<div *ngIf="percentage | async as pct">
    <mat-progress-bar *ngIf="percentage" mode="determinate" [value]="pct"></mat-progress-bar>
  </div>
<div *ngIf="snapshot | async as snap">
    {{snap.ref.name}}
</div>
<div class="feedback">
  {{feedback}}
</div>

