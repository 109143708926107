<div id="content-container">
  <div>
    <h2 mat-dialog-title>Upload an MP3</h2>
  </div>
  <div></div>
  <br />
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Select a year</mat-label>
      <mat-select [(value)]="yearPicked">
        <mat-option *ngFor="let year of years" [value]="year.id">
          {{year.id}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <p></p>
  <div>
    <mat-dialog-content class="mat-typography" style="display: inline">
      <input type="file" multiple (change)="onSelectFiles($event)" />
      <p></p>
      <div class="mat-typography" style="color:red">{{feedback}}</div>
      <div>
        <p></p>
        <div *ngFor="let file of files">
          <app-upload-task [file]="file" [yearPicked]="yearPicked"></app-upload-task>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Close</button>
    </mat-dialog-actions>
  </div>
</div>