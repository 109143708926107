<div fxLayout="column" fxLayoutGap="0px">
<div class="child2">
  <app-welcome-block></app-welcome-block>
</div>
<div class="child3">
  <div class='sermonBlock'>
    <mat-tab-group style="min-height: 90%;" animationDuration="0ms">
      <mat-tab *ngFor="let tab of tabs$">
        <ng-template mat-tab-label>{{tab.id}}</ng-template>
        <ng-template matTabContent>
          <app-sermon-diplay style="min-height: 90%;" [year]='tab.id'></app-sermon-diplay>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
</div>