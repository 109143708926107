<div *ngIf="sermons$">
  <mat-list fxLayout="row wrap" fxLayout.xs="column"  fxFlex style="margin-bottom:5%">
    <mat-list-item class="listItem" *ngFor='let sermon of sermons$ | async'>

      <div fxFlex fxFlexAlign="center" class="item1" aria-label='Sermon Filename'>{{sermon.filename}}</div>

      <div fxFlex class="item2">
        <button #tooltip="matTooltip" matTooltip="Download or Listen to the sermon" matTooltipPosition="below"
          mat-icon-button color="primary" (click)="goToDownloadPage(sermon.downloadURL)"
          aria-label="Cloud download icon">
          <mat-icon>cloud_download</mat-icon>
        </button>

        <button mat-icon-button #tooltip="matTooltip" matTooltip="Send this file to be converted to text for reading"
          matTooltipPosition="below" color="primary" [disabled]="sermon.text" (click)="sendFileForTranscription(sermon)"
          aria-label="Google Translate Icon">
          <mat-icon>g_translate</mat-icon>
        </button>

        <button mat-icon-button #tooltip="matTooltip" matTooltip="Read the transcript of this sermon"
          matTooltipPosition="below" [disabled]="sermon.text ? null : true"
          (click)="getTranslatedText(sermon.uuid, sermon.year)" color="primary" aria-label="Open Book Icon">
          <mat-icon>chrome_reader_mode</mat-icon>
        </button>

      </div>

    </mat-list-item>

  </mat-list>
</div>