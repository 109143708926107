<div class="container">
  <div class="searchBox">
    <form class="search-form">
      <mat-form-field class="full-width">
        <mat-label>Search a phrase or word</mat-label>
        <input matInput (input)="currentItem=getValue($event.target)" #message maxlength="256"
          placeholder="Enter word or phrase...">
      </mat-form-field>
    </form>
    <div class="searchResultContainer" *ngFor="let result of filterSermons">
      <mat-accordion>
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{result.filename}}
            </mat-panel-title>
            <mat-panel-description>
              {{result.year}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div innerHTML="{{result.text | highlight: currentItem}}"></div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>