<div class="mat-body" style="margin-top: 20px;">
        <span>
                <img src="assets/img/logo.png" width="125px" height="70px"  style="margin-top: 10px; max-width:250px" >
            </span>
        <p>700 Brentwood Drive. Lititz, PA 17543</p>
        <p>
                <a href="https://www.facebook.com/lifechurchlancaster/"><img
                                src="../../assets/img/icon/circleFacebook1.svg" style="width: 40px; height: 40px;"></a>
                <a href="https://twitter.com/lifechurchlanc"><img src="../../assets/img/icon/circleTwitter1.svg"
                                style="width: 40px; height: 40px;"></a>
                <a href="https://itunes.apple.com/us/podcast/life-church-lancaster-podcasts/id408232211"><img
                                src="../../assets/img/icon/circleApple.svg" style="width: 40px; height: 40px;"></a>
                <a href="https://www.youtube.com/channel/UChOL5zZuG_UnYmV2cbXzjCA"><img
                                src="../../assets/img/icon/circleYoutube.svg" style="width: 40px; height: 40px;"></a>
                <a href="http://instagram.com/lifechurchlancaster"><img src="../../assets/img/icon/circleInstagram.svg"
                                style="width: 40px; height: 40px;"></a>
        </p>
        <p><a href="https://lifechurchlancaster.org">LifeChurchLancaster.org</a></p>

</div>